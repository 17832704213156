<template>
  <v-form ref="form" v-model="valid" @submit.prevent="login()">
    <v-alert v-if="error" text color="error">{{ error }}</v-alert>
    <v-text-field label="Email" v-model="email" outlined></v-text-field>
    <v-text-field
      :type="showPassword ? 'text' : 'password'"
      label="Password"
      hint="Password is case sensitive"
      :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
      @click:append="togglePassword()"
      v-model="password"
      outlined
    ></v-text-field>
    <input type="submit" hidden />
    <v-btn
      color="primary"
      class="text-capitalize"
      :loading="loading"
      block
      large
      @click="login()"
      >Login</v-btn
    >
  </v-form>
</template>

<script>
import rules from '@/mixins/rules'
import firebase from 'firebase/app'
import 'firebase/auth'
import user from '@/mixins/user'
export default {
  mixins: [rules, user],
  data: () => ({
    email: '',
    password: '',
    valid: true,
    error: null,
    showPassword: false,
    loading: false
  }),
  beforeMount() {
    let token = this.$route.query.token
    if (token) {
      this.loginWithToken(token)
    }
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword
    },
    updateLogin() {
      this.$store.commit('auth', true)
      let next = this.$route.query.next || '/parcels'
      this.$router.push(next)
    },
    showError(error) {
      if (error.message) {
        this.error = error.message
      } else {
        this.error = 'Something went wrong'
      }
    },

    async login() {
      this.loading = true
      this.error = null
      this.$refs.form.validate()

      this.email = this.email.trim()
      this.password = this.password.trim()

      if (this.valid) {
        await firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
            this.updateLogin()
          })
          .catch(error => {
            this.showError(error)
          })
      }
      this.loading = false
    },
    async loginWithToken(token) {
      this.loading = true
      await firebase
        .auth()
        .signInWithCustomToken(token)
        .then(() => {
          this.updateLogin()
        })
        .catch(error => {
          this.showError(error)
        })
      this.loading = false
    }
  }
}
</script>

<style></style>
