<template>
  <v-main>
    <v-container>
      <v-card class="mx-auto" max-width="600">
        <v-card-text class="pa-8 pt-4">
          <div class="mb-5 text-center">
            <h1>Login</h1>
          </div>
          <login />
          <div class="text-center mt-2 mb-2">
            <router-link to="/account-recovery" style="text-decoration: none"
              >Forgot Password?</router-link
            >
          </div>
          <v-divider class="mt-3 mb-3"></v-divider>
          <div class="d-block d-sm-flex justify-center">
            <div>
              <v-img
                max-width="150"
                src="@/assets/img/PeepsProne.svg"
                class="mx-auto"
              ></v-img>
            </div>
            <div class="text-center">
              <p class="lead">Don't have a Peeps account?</p>
              <v-btn to="/signup" color="success" class="text-capitalize" large
                >Create A Free Account</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import Login from '@/components/Login'
export default {
  components: {
    Login
  },
  metaInfo: {
    meta: [
      {
        name: 'description',
        content: ''
      }
    ],
    title: 'Login'
  }
}
</script>
